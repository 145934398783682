import { CTAColorVariant } from "~/components/shared/CTAButton";

export const DEFAULT_PROPS = {
    headline: 'Experience Walmart+ benefits & more for 30 days—free',
    subheadline: '',
    // TODO: update to match better conventions post banner removal to decrease risk of breakage to existing assets
    // subheadline: {
    //     text: '',
    //     link: ''
    // },
    disclaimer: {
        text: '',
        links: []
    },
    CTAButtonText: 'Claim your free trial',
    CTAButtonVariant: 'primary-blue' as CTAColorVariant,
    CTAButtonLink: 'https://www.walmart.com/plus/plans',
    hasSecondaryCTAButton: false,
    secondaryCTAButtonText: 'Or try free for 30 days',
    secondaryCTAButtonVariant: 'text-link' as CTAColorVariant,
    secondaryCTAButtonLink: 'https://www.walmart.com/plus/plans',
};

// temporary variant data for base site addition for Student LTO
export const student_lto_data = {
    headline: 'Limited time: Get 50% off membership for students',
    subheadline: 'College & grad students can get a Walmart+ membership for just $6.47/mo or $49/yr—that’s half off! Ends 9/13.',
    disclaimer: {
        text: 'For eligible college & grad students. Ends 9/13/2024. _LINK_Terms apply._LINK_ Subject to change. Don’t qualify? _LINK_Try a full priced plan for free._LINK_',
        links: ['https://www.walmart.com/help/article/walmart-terms-of-use/de696dfa1dd4423bb1005668dd19b845', 'https://www.walmart.com/plus/chooseplan']
    },
    CTAButtonText: 'Get Walmart+ Student',
    CTAButtonVariant: 'primary-blue' as CTAColorVariant,
    CTAButtonLink: 'https://www.walmart.com/plus/student/verify',
    hasSecondaryCTAButton: false,
    secondaryCTAButtonText: '',
    secondaryCTAButtonVariant: 'text-link' as CTAColorVariant,
    secondaryCTAButtonLink: '',
}