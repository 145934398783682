import type { CoreContentCardProps } from '~/components/shared/ContentCard';

const baseUrl = process.env.baseUrl;

const baseSiteFeatures: CoreContentCardProps[] = [
  {
    heading: 'Auto Care for less',
    subheading:
      'Get free flat tire repair* & free road hazard warranty on new tires** at any Walmart Auto Care Center.',
    imgSrc: `${baseUrl}/images/sections/features/auto/auto-care.jpeg`,
    imageProps: {
      alt: 'Children laughing while washing the family car.',
      width: 375,
      height: 350,
    },
    valueTag: 'New',
    startDate: new Date('10/19/2023'),
    disclaimer:
      '*Active Walmart+ membership required at time of repair. Vehicle & tire restrictions apply. _LINK_See full restrictions & terms here._LINK_ **Limited to tire(s) purchased & installed at Walmart Auto Care Centers. Active Walmart+ membership required at time of purchase & installation. Vehicle restrictions apply. _LINK_See full restrictions & terms here._LINK_',
    disclaimerLink: 'https://www.walmart.com/help/article/walmart-terms-of-use/de696dfa1dd4423bb1005668dd19b845',
  },
  {
    heading: 'Book travel easily & get rewarded',
    subheading:
      'Plan air, hotels & more with your membership, powered by Expedia. Get up to 5% Walmart Cash on your bookings to make every trip more rewarding.*',
    imgSrc: `${baseUrl}/images/sections/features/travel/travel.jpeg`,
    imageProps: {
      alt: 'A family placing travel items into their car. A women and man holding suitcases, whilst a walmart bag sits in the trunk. With a light-yellow house in the background',
      width: 375,
      height: 350,
    },
    valueTag: 'New',
    startDate: new Date('07/25/2023'),
    disclaimer:
      '*Subject to Walmart Cash term and Walmart+ terms.',
  },
  {
    heading: 'Unlimited grocery delivery from your local store',
    subheading:
      "Enjoy free delivery on all of your essentials as soon as today, on top of Walmart's everyday low prices.",
    imgSrc: `${baseUrl}/images/sections/features/groceries/groceries.jpg`,
    imageProps: {
      alt: 'A bagful of groceries on a kitchen countertop, next to a box full of kitchen and home supplies.',
      width: 375,
      height: 350,
    },
    disclaimer:
      '*$35 order minimum. Restrictions apply. Check availability in your area at sign-up. Additional fee applies for Express delivery.',
  },
  {
    heading: 'Return items from home',
    subheading:
      'We come to you! Set up returns in your account & we’ll come pick it up. No printing, no repacking, no need to leave your home.',
    imgSrc: `${baseUrl}/images/sections/features/return-from-home/return-from-home.jpeg`,
    imageProps: {
      alt: 'A mom is sitting on the floor looking at her child with a phone in hand',
      width: 375,
      height: 350,
    },
    disclaimer:
      'Must be present for pickup. _LINK_Restrictions apply._LINK_',
    disclaimerLink: 'https://www.walmart.com/help/article/walmart-terms-of-use/de696dfa1dd4423bb1005668dd19b845',
  },
  {
    heading: 'More low prices & options with online shipping',
    subheading:
      'Get the products you simply must have from a larger selection when shopping online, without the $35 order minimum.',
    imgSrc: `${baseUrl}/images/sections/features/products/products.jpg`,
    imageProps: {
      alt: 'A woman is petting her dog who is sitting behind a box filled with pet and home supplies from Walmart.',
      width: 375,
      height: 350,
    },
    disclaimer:
      '*Excludes most Marketplace items, location & freight surcharges.',
  },
  {
    heading: 'Fuel savings at many locations',
    subheading:
      'Enjoy fuel perks at over 13,000 locations, such as Exxon, Mobil, Sam’s Club, Walmart & Murphy stations.',
    imgSrc: `${baseUrl}/images/sections/features/fuel/fuel.jpeg`,
    imageProps: {
      alt: 'A customer prepares to pay at a gas pump using their mobile phone.',
      width: 375,
      height: 350,
    },
    disclaimer:
      '*Fuel discount varies by location & station, subject to change.',
  },
  {
    heading: 'Early access to deals',
    subheading:
      'Walmart+ paid members get exclusive early access to special promotions & events—including online Black Friday deals, product releases & more.',
    imgSrc: `${baseUrl}/images/sections/features/deals/deals.jpg`,
    imageProps: {
      alt: 'A bag full of kitchen supplies and groceries sits on top of a counter top surrounded by cleaning supplies and coffee grounds.',
      width: 375,
      height: 350,
    },
    disclaimer: '*Available to paid Walmart+ members only. While supplies last.',
  },
  {
    heading: 'Video streaming with Paramount+',
    subheading:
      'Enjoy over 40,000 episodes, hit movies, live sports & more with a Paramount+ subscription included with your W+ membership!',
    imgSrc: `${baseUrl}/images/sections/features/promotions/paramount-promotion.jpeg`,
    imageProps: {
      alt: 'A couple are sitting on a couch, remote in hand, watching Star Trek: Discovery on the Paramount+ streaming service on a TV',
      width: 375,
      height: 350,
    },
    disclaimer: '*Paramount+ Essential plan only, separate registration required.',
  },
  {
    heading: 'Mobile scan & go',
    subheading:
      'With contact-free checkout, you can spend less time at the register & more time enjoying your purchase.',
    imgSrc: `${baseUrl}/images/sections/features/scan_and_go/scan_and_go.jpeg`,
    imageProps: {
      alt: 'A customer scanning a product using their mobile phone at the grocery store',
      width: 375,
      height: 350,
    },
  },
  {
    heading: 'Video streaming with Pluto TV',
    subheading:
      'Watch select ad-free streaming content that changes each month.',
    imgSrc: `${baseUrl}/images/sections/features/pluto/pluto-tv.jpeg`,
    imageProps: {
      alt: 'A customer streaming plutoTV from their couch',
      width: 375,
      height: 350,
    },
  },
];

export default baseSiteFeatures;
